import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  subHeader: {
    fontSize: 16,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: '100%',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 30,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  },
  tableCol: {
    width: '20%',
    padding: 5,
  },
  text: {
    fontSize: 10,
  },
  dateRange: {
    fontSize: 12,
    marginBottom: 15,
    textAlign: 'center',
  },
  totalRow: {
    backgroundColor: '#f0f0f0',
    fontWeight: 'bold',
  }
});

const NRWReport = ({ data, startDate, endDate }) => {
  // Calculate totals with default values
  const totals = data.reduce((acc, dma) => ({
    customers: (acc.customers || 0) + (Number(dma.Customers) || 0),
    masterMeter: (acc.masterMeter || 0) + (Number(dma.Mast_Cons) || 0),
    customerCons: (acc.customerCons || 0) + (Number(dma.Cust_Cons) || 0),
    nrwVolume: (acc.nrwVolume || 0) + (Number(dma.NRW_Volume) || 0),
  }), {
    customers: 0,
    masterMeter: 0,
    customerCons: 0,
    nrwVolume: 0
  });

  // Calculate overall NRW Ratio with null check
  const overallNRWRatio = totals.masterMeter > 0 
    ? ((totals.nrwVolume / totals.masterMeter) * 100).toFixed(2)
    : '0.00';

  // Helper function to format numbers safely
  const formatNumber = (value) => {
    const num = Number(value);
    return isNaN(num) ? '0.00' : num.toFixed(2);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>NRW Analysis Report</Text>
        <Text style={styles.dateRange}>
          Period: {new Date(startDate).toLocaleDateString()} - {new Date(endDate).toLocaleDateString()}
        </Text>

        {/* Summary Table */}
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>DMA</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Total Customers</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Master Meter (m³)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>Customer Cons (m³)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>NRW Volume (m³)</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>NRW Ratio (%)</Text>
            </View>
          </View>

          {data.map((dma, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{dma.DMAName || ''}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{dma.Customers || 0}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{formatNumber(dma.Mast_Cons)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{formatNumber(dma.Cust_Cons)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{formatNumber(dma.NRW_Volume)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.text}>{formatNumber(dma.NRW_Ratio)}%</Text>
              </View>
            </View>
          ))}

          {/* Totals Row */}
          <View style={[styles.tableRow, styles.totalRow]}>
            <View style={styles.tableCol}>
              <Text style={styles.text}>TOTALS</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{totals.customers}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{formatNumber(totals.masterMeter)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{formatNumber(totals.customerCons)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{formatNumber(totals.nrwVolume)}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.text}>{overallNRWRatio}%</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default NRWReport; 
import { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Card,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  DialogContentText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ArrowForwardIos,
  Download,
  LineAxis,
  Edit as EditIcon,
  Delete as DeleteIcon,
  PictureAsPdf,
} from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { BarChart, LineChart } from "recharts";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import NRWReport from "./NRWReport";

// First, let's move the DMA list to a constant so it can be reused
const ALL_DMAS = [
  "Kamiti A",
  "Kamiti B",
  "Kanjata",
  "Kiu River",
  "Kiu Kenda",
  "Kiambu Golf Club",
  "Makanja 1",
  "Makanja 2",
  "Samaki 1",
  "Samaki 2",
];

export default function DMAAnalysis2() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dmas, setDMAs] = useState("All");
  const [type, setType] = useState("All");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editData, setEditData] = useState(null);
  const today = new Date();
  const [startDate, setStartDate] = useState("2025-01-01");
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]);
  const [stats, setStats] = useState(null);
  const [searchAccount, setSearchAccount] = useState("");
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [dmaStats, setDmaStats] = useState([]);

  // Add data caching
  const [dataCache, setDataCache] = useState({});
  const cacheKey = `${dmas}-${type}-${startDate}-${endDate}`;

  // Debounce the date changes
  const debouncedStartDate = useDebounce(startDate, 500);
  const debouncedEndDate = useDebounce(endDate, 500);

  // Memoize the stats data
  const memoizedStats = useMemo(() => stats, [stats]);

  let selType = type !== "All" ? `${type}` : "All";
  let selDma = dmas !== "All" ? `${dmas}` : "All";

  useEffect(() => {
    setLoading(true);
    fetch(
      `/api/nrwreading/dma/stats/${selDma}/${startDate}/${endDate}?deletedAt=null`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Received data:", data);
        if (data && typeof data === "object") {
          setStats(data);
        } else {
          console.error("Invalid data format received:", data);
          setStats({
            Customers: 0,
            Cust_Cons: 0,
            Mast_Cons: 0,
            NRW_Volume: 0,
            NRW_Ratio: 0,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setLoading(false);
      });
  }, [selDma, startDate, endDate]);

  // Optimize data fetching
  const fetchData = useCallback(async () => {
    // Check cache first
    if (dataCache[cacheKey]) {
      setData(dataCache[cacheKey]);
      return;
    }

    setLoading(true);
    try {
      const res = await fetch(
        `/api/nrwreading/${selDma}/${selType}/${startDate}/${endDate}?deletedAt=null`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!res.ok) throw new Error("Could not fetch data!");

      const newData = await res.json();
      setData(newData);

      // Update cache
      setDataCache((prev) => ({
        ...prev,
        [cacheKey]: newData,
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [selDma, selType, startDate, endDate, cacheKey]);

  // Use debounced values for data fetching
  useEffect(() => {
    fetchData();
  }, [debouncedStartDate, debouncedEndDate, selDma, selType]);

  const handleRowClick = (params) => {
    const selectedItem = data?.data?.find((item) => item.ID === params.row.ID);
    setSelectedRow(selectedItem);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const handleEdit = (row) => {
    setEditData({
      ...row,
      ID: row.ID,
      FirstReading: String(row.FirstReading),
      SecondReading: String(row.SecondReading),
    });
    setOpenEditDialog(true);
  };

  const handleDelete = async (row) => {
    setDeleteId(row.ID);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteDialog(false);
    setOpenDeleteConfirmDialog(true);
  };

  const handleFinalDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/nrwreading/${deleteId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to delete");
      }

      if (data.error) {
        throw new Error(data.error);
      }

      await fetchData(); // Refresh data after deletion
      setOpenDeleteConfirmDialog(false);
    } catch (error) {
      console.error("Error deleting record:", error);
    } finally {
      setLoading(false);
      setDeleteId(null); // Clear the deleteId after operation
      if (openDialog) {
        setOpenDialog(false); // Close the details dialog if it's open
      }
    }
  };

  const handleSaveEdit = async () => {
    if (!editData || !editData.ID) {
      alert("Invalid record to update");
      return;
    }
    setOpenUpdateConfirmDialog(true);
  };

  const handleFinalUpdate = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/nrwreading/${editData.ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          DMAName: editData.DMAName,
          MeterType: editData.MeterType,
          AccountNo: editData.AccountNo,
          FirstReading: editData.FirstReading,
          SecondReading: editData.SecondReading,
          FirstReadingDate: editData.FirstReadingDate,
          SecondReadingDate: editData.SecondReadingDate,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update");
      }

      await fetchData(); // Refresh data after update
      setOpenUpdateConfirmDialog(false);
      setOpenEditDialog(false);
      setEditData(null);

      // Update the selected row data if needed
      if (selectedRow && selectedRow.ID === editData.ID) {
        const updatedRow = data?.data?.find((item) => item.ID === editData.ID);
        if (updatedRow) {
          setSelectedRow(updatedRow);
        }
      }
    } catch (error) {
      console.error("Error updating record:", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: "sn", headerName: "ID", flex: 0.5, sortable: false },
    { field: "DMAName", headerName: "DMA", flex: 1 },
    { field: "MeterType", headerName: "Meter Type", flex: 1 },
    { field: "AccountNo", headerName: "Account", flex: 1 },
    { field: "FirstReadingDate", headerName: "First Reading Date", flex: 1 },
    { field: "SecondReadingDate", headerName: "Second Reading Date", flex: 1 },
    { field: "FirstReading", headerName: "First Reading", flex: 1 },
    { field: "SecondReading", headerName: "Second Reading", flex: 1 },
    { field: "Consumption", headerName: "Consumption", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap={1} onClick={(e) => e.stopPropagation()}>
          <IconButton
            size="small"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(params.row);
            }}
            title="Edit"
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(params.row);
            }}
            title="Delete"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows =
    data && data?.data
      ? data?.data
          ?.map((item) => ({
            id: item.ID,
            ID: item.ID,
            sn: item.ID,
            DMAName: item.DMAName,
            MeterType: item.MeterType,
            AccountNo: item.AccountNo,
            FirstReadingDate: item.FirstReadingDate,
            SecondReadingDate: item.SecondReadingDate,
            FirstReading: item.FirstReading,
            SecondReading: item.SecondReading,
            Consumption: item.Consumption,
          }))
          .filter(
            (row) =>
              searchAccount === "" ||
              row.AccountNo.toLowerCase().includes(searchAccount.toLowerCase())
          )
      : [];

  const downloadCSV = () => {
    if (!data || !data.data) return;

    const headers = [
      "SN",
      "DMA",
      "Meter Type",
      "Account",
      "FirstReadingDate",
      "SecondReadingDate",
      "First Reading",
      "Second Reading",
      "Consumption",
    ];
    const rows = data?.data?.map((item, index) => [
      index + 1,
      item.DMAName,
      item.MeterType,
      item.AccountNo,
      item.FirstReadingDate,
      item.SecondReadingDate,
      item.FirstReading, // Replace with correct data
      item.SecondReading, // Replace with correct data
      Number(item.SecondReading) - Number(item.FirstReading), // Replace with correct calculation if available
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dma_analysis_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Optimize report generation
  const fetchAllDMAStats = async () => {
    const batchSize = 3; // Process DMAs in smaller batches
    const results = [];

    for (let i = 0; i < ALL_DMAS.length; i += batchSize) {
      const batch = ALL_DMAS.slice(i, i + batchSize);
      const batchPromises = batch.map((dma) =>
        fetch(
          `/api/nrwreading/dma/stats/${dma}/${startDate}/${endDate}?deletedAt=null`,
          {
            credentials: "include",
          }
        ).then((res) => res.json())
      );

      const batchResults = await Promise.all(batchPromises);
      results.push(
        ...batchResults.map((stat, index) => ({
          DMAName: batch[index],
          ...stat,
        }))
      );
    }

    setDmaStats(results);
  };

  return (
    <Box>
      <Stack spacing={3}>
        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          {/* Left side - Search and Filters */}
          <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>DMA</InputLabel>
              <Select
                value={dmas}
                onChange={(e) => setDMAs(e.target.value)}
                label="DMA"
              >
                <MenuItem value="All">All</MenuItem>
                {ALL_DMAS.map((dma) => (
                  <MenuItem key={dma} value={dma}>
                    {dma}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Meter Type</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                label="Meter Type"
              >
                {["All", "Master Meter", "Customer Meter"].map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              size="small"
              label="Search Account"
              value={searchAccount}
              onChange={(e) => setSearchAccount(e.target.value)}
              sx={{ width: 150 }}
            />
          </Box>

          {/* Right side - Date Range and Actions */}
          <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
            <TextField
              value={startDate}
              label="From"
              type="date"
              size="small"
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ width: 130 }}
            />
            <TextField
              value={endDate}
              label="To"
              type="date"
              size="small"
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ width: 130 }}
            />

            <Box display="flex" gap={1}>
              <Button
                color="secondary"
                variant="contained"
                onClick={downloadCSV}
                size="small"
                startIcon={<Download />}
              >
                CSV
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  fetchAllDMAStats();
                  setOpenReportDialog(true);
                }}
                size="small"
                startIcon={<PictureAsPdf />}
                disabled={loading}
                sx={{
                  backgroundColor: "#2196f3",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                  },
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  textTransform: "none",
                }}
              >
                {loading ? "Generating..." : "Report"}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Total Customers"
                color="#219B9D"
                value={stats?.Customers || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Customer Cons"
                color="#8B5DFF"
                value={stats?.Cust_Cons || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="Mastermeter Cons"
                color="#219B9D"
                value={stats?.Mast_Cons || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="NRW Volume"
                color="#FF8000"
                value={stats?.NRW_Volume || 0}
              />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <CardItem
                title="NRW Ratio"
                color="#3D3BF3"
                value={stats?.NRW_Ratio || 0}
              />
            </Grid>
          </Grid>
        </Box>

        <Card
          style={{
            borderRadius: "10px",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box sx={{ height: "60vh", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={12}
              rowsPerPageOptions={[12]}
              pagination
              onRowClick={handleRowClick}
              loading={loading}
              sx={{
                cursor: "pointer",
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                ".MuiDataGrid-columnHeader:focus": {
                  outline: "none",
                },
                ".MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
              }}
              components={{
                LoadingOverlay: () => (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ),
              }}
            />
          </Box>
        </Card>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 1.5,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <DialogTitle
            sx={{
              borderBottom: "1px solid #e0e0e0",
              px: 2.5,
              py: 1.5,
              backgroundColor: "#f8f9fa",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, color: "#2c3e50", fontSize: "1.1rem" }}
            >
              Meter Reading Details
            </Typography>
            <Button
              onClick={handleCloseDialog}
              size="small"
              sx={{ minWidth: "auto", p: 0.5 }}
            >
              ×
            </Button>
          </DialogTitle>
          <DialogContent sx={{ p: 2.5 }}>
            {selectedRow && (
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  {/* Basic Info Section */}
                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        DMA Name
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#2c3e50" }}>
                        {selectedRow.DMAName}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Meter Type
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#2c3e50" }}>
                        {selectedRow.MeterType}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Account Number
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#2c3e50" }}>
                        {selectedRow.AccountNo}
                      </Typography>
                    </Stack>
                  </Grid>

                  {/* Readings Section */}
                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        First Reading
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#2c3e50" }}>
                        {selectedRow.FirstReading}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Second Reading
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#2c3e50" }}>
                        {selectedRow.SecondReading}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={0.5}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        Consumption
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#2c3e50",
                          fontWeight: 600,
                          fontSize: "0.95rem",
                        }}
                      >
                        {selectedRow.Consumption}
                      </Typography>
                    </Stack>
                  </Grid>

                  {/* Image Section */}
                  {(selectedRow.Image || selectedRow.SR_Image) && (
                    <>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                        <Typography
                          variant="caption"
                          sx={{
                            color: "text.secondary",
                            fontWeight: 600,
                            display: "block",
                            mb: 1,
                          }}
                        >
                          Meter Images
                        </Typography>
                      </Grid>
                      <Grid item xs={12} container spacing={2}>
                        {/* First Reading Image */}
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "text.secondary",
                              fontWeight: 600,
                              display: "block",
                              mb: 0.5,
                            }}
                          >
                            First Reading Image
                          </Typography>
                          {selectedRow.Image ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: 250,
                                position: "relative",
                                bgcolor: "#f8f9fa",
                                border: "1px solid #e0e0e0",
                                borderRadius: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={`/api/uploads/${selectedRow.Image}`}
                                alt="First Reading"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  display: "none", // Initially hidden until loaded
                                }}
                                onLoad={(e) => {
                                  e.target.style.display = "block";
                                }}
                                onError={(e) => {
                                  console.error("Image load error:", e);
                                  if (!e.target.src.includes("/uploads/")) {
                                    e.target.src = `/uploads/${selectedRow.Image}`;
                                  } else {
                                    e.target.style.display = "none";
                                  }
                                }}
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress />
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                height: 250,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                                bgcolor: "#f8f9fa",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                No image available
                              </Typography>
                            </Box>
                          )}
                        </Grid>

                        {/* Second Reading Image */}
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "text.secondary",
                              fontWeight: 600,
                              display: "block",
                              mb: 0.5,
                            }}
                          >
                            Second Reading Image
                          </Typography>
                          {selectedRow.SR_Image ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: 250,
                                position: "relative",
                                bgcolor: "#f8f9fa",
                                border: "1px solid #e0e0e0",
                                borderRadius: 1,
                              }}
                            >
                              <Box
                                component="img"
                                src={`/api/uploads/${selectedRow.SR_Image}`}
                                alt="Second Reading"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  display: "none", // Initially hidden until loaded
                                }}
                                onLoad={(e) => {
                                  e.target.style.display = "block";
                                }}
                                onError={(e) => {
                                  console.error("Image load error:", e);
                                  if (!e.target.src.includes("/uploads/")) {
                                    e.target.src = `/uploads/${selectedRow.SR_Image}`;
                                  } else {
                                    e.target.style.display = "none";
                                  }
                                }}
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress />
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                height: 250,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                                bgcolor: "#f8f9fa",
                                border: "1px solid #e0e0e0",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                No image available
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            )}
          </DialogContent>
        </Dialog>

        {/* First Delete Dialog */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Delete Record</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this record?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
            >
              Proceed to Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Final Delete Confirmation Dialog */}
        <Dialog
          open={openDeleteConfirmDialog}
          onClose={() => setOpenDeleteConfirmDialog(false)}
        >
          <DialogTitle>Final Delete Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This is your final confirmation. The record will be permanently
              deleted and cannot be recovered. Are you absolutely sure you want
              to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirmDialog(false)}>
              No, Keep Record
            </Button>
            <Button
              onClick={handleFinalDelete}
              color="error"
              variant="contained"
              disabled={loading}
            >
              {loading ? "Deleting..." : "Yes, Delete Permanently"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Update Confirmation Dialog */}
        <Dialog
          open={openUpdateConfirmDialog}
          onClose={() => setOpenUpdateConfirmDialog(false)}
        >
          <DialogTitle>Confirm Update</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to update this record with the new
              information? This action will modify the existing data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpdateConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleFinalUpdate}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              {loading ? "Updating..." : "Yes, Update Record"}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Edit Dialog */}
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            <Box display="flex" alignItems="center" gap={1}>
              <EditIcon fontSize="small" />
              Edit Record
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>DMA</InputLabel>
                <Select
                  value={editData?.DMAName || ""}
                  onChange={(e) =>
                    setEditData({ ...editData, DMAName: e.target.value })
                  }
                  label="DMA"
                >
                  {[
                    "Kamiti A",
                    "Kamiti B",
                    "Kanjata",
                    "Kiu River",
                    "Kiu Kenda",
                    "Kiambu Golf Club",
                    "Makanja 1",
                    "Makanja 2",
                    "Samaki 1",
                    "Samaki 2",
                  ].map((dma) => (
                    <MenuItem key={dma} value={dma}>
                      {dma}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Meter Type</InputLabel>
                <Select
                  value={editData?.MeterType || ""}
                  onChange={(e) =>
                    setEditData({ ...editData, MeterType: e.target.value })
                  }
                  label="Meter Type"
                >
                  {["Master Meter", "Customer Meter"].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                label="Account Number"
                value={editData?.AccountNo || ""}
                onChange={(e) =>
                  setEditData({ ...editData, AccountNo: e.target.value })
                }
              />

              <TextField
                fullWidth
                label="First Reading"
                type="number"
                value={editData?.FirstReading || ""}
                onChange={(e) =>
                  setEditData({ ...editData, FirstReading: e.target.value })
                }
              />

              <TextField
                fullWidth
                label="Second Reading"
                type="number"
                value={editData?.SecondReading || ""}
                onChange={(e) =>
                  setEditData({ ...editData, SecondReading: e.target.value })
                }
              />

              <TextField
                fullWidth
                label="First Reading Date"
                type="date"
                value={editData?.FirstReadingDate || ""}
                onChange={(e) =>
                  setEditData({ ...editData, FirstReadingDate: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                fullWidth
                label="Second Reading Date"
                type="date"
                value={editData?.SecondReadingDate || ""}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    SecondReadingDate: e.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
            <Button
              onClick={handleSaveEdit}
              variant="contained"
              color="primary"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Report Dialog */}
        <Dialog
          open={openReportDialog}
          onClose={() => setOpenReportDialog(false)}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>NRW Analysis Report</DialogTitle>
          <DialogContent>
            <Box sx={{ height: "70vh" }}>
              <PDFViewer width="100%" height="100%">
                <NRWReport
                  data={dmaStats}
                  startDate={startDate}
                  endDate={endDate}
                />
              </PDFViewer>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenReportDialog(false)}>Close</Button>
            <PDFDownloadLink
              document={
                <NRWReport
                  data={dmaStats}
                  startDate={startDate}
                  endDate={endDate}
                />
              }
              fileName={`NRW_Report_${startDate}_${endDate}.pdf`}
            >
              {({ loading }) => (
                <Button color="primary" variant="contained" disabled={loading}>
                  {loading ? "Preparing..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}

const CardItem = ({ title, value, color, current, last, positive }) => {
  return (
    <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
      <Card
        sx={{
          p: 2,
          borderRadius: 3,
          backgroundColor: color,
          color: "white",
          boxShadow: "0px 4px 8px #60606020",
        }}
      >
        <Stack spacing={1}>
          <Box display="flex" gap={1}>
            <Typography flexGrow={1} sx={{ fontSize: "small" }}>
              {title}
            </Typography>
          </Box>
          <Typography
            variant="title"
            sx={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            {title === "Total Customers"
              ? value
              : Number(value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </Typography>
        </Stack>
      </Card>
    </Grid2>
  );
};

// Custom debounce hook
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Box } from "@mui/material";
import jwt from "jsonwebtoken";

export default function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(null);

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const token = localStorage.getItem("gdfhgfhtkn");
      const decoded = jwt.decode(token);
      const userId = decoded.UserID;
      const response = await fetch(`/api/auth/${userId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Password: oldPassword,
          NewPassword: newPassword,
        }),
      });

      if (response.status === 203) {
        const data = await response.json();
        throw new Error(data.message || "Failed to update password");
      }

      setSuccess("Password updated successfully");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      setError(err.message || "Failed to update password");
    }
  };

  return (
    <Box marginTop={8} padding={1}>
      <Stack spacing={3}>
        <div>
          <Typography variant="h4">Settings</Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Card
            style={{
              borderRadius: "16px",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.08)",
            }}
          >
            <CardHeader subheader="Update password" title="Password" />
            <Divider />
            <CardContent>
              <Stack spacing={3} sx={{ maxWidth: "sm" }}>
                <FormControl fullWidth>
                  <InputLabel>Old Password</InputLabel>
                  <OutlinedInput
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>New Password</InputLabel>
                  <OutlinedInput
                    label="New Password"
                    name="newPassword"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel>Confirm password</InputLabel>
                  <OutlinedInput
                    label="Confirm password"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                {error && <Typography color="error">{error}</Typography>}
                {success && (
                  <Typography color="success.main">{success}</Typography>
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Box>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Home from "../Pages/Home";
import Users from "../Pages/Users";
import Settings from "../Pages/Settings";
import MyMap from "../Pages/MyMap";
import AllData from "../Pages/AllData";
import NRW from "../Pages/NRW";
import Billing from "../Pages/Billing";
import BillingDetails from "./Billing2/BillingDetails";
import Operations from "../Pages/Operations";
import NRWManagement from "./NRW2/NRWManagement";
import NRWMap from "./NRW2/NRWMap";

function Dashboard() {
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="nrw" element={<NRW />} />
          <Route path="nrw/management" element={<NRWManagement />} />
          <Route path="nrw/map" element={<NRWMap />} />
          <Route path="data" element={<AllData />} />
          <Route path="billing" element={<Billing />} />
          <Route path="billing/*" element={<BillingDetails />} />
          <Route path="users" element={<Users />} />
          <Route path="map" element={<MyMap />} />
          <Route path="settings" element={<Settings />} />
          <Route path="operations" element={<Operations />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;

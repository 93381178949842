// Home.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  useTheme,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { BarChart, PieChart, LineChart } from "@mui/x-charts";
import {
  PeopleAltOutlined,
  ReportProblemOutlined,
  WaterDrop,
  WaterDamage,
} from "@mui/icons-material";

// Add this before the HomePage component
const fetchWithTimeout = (url, timeout = 30000) => {
  return Promise.race([
    fetch(url),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Request timeout")), timeout)
    ),
  ]);
};

// Summary Card Component with enhanced styling and visibility
const SummaryCard = ({ title, value, percentage, color, icon: Icon }) => {
  return (
    <Paper elevation={3} sx={{ height: "100%", borderRadius: 2 }}>
      <Card sx={{ height: "100%", bgcolor: "background.paper" }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            {Icon && <Icon sx={{ mr: 1, color: color }} />}
            <Typography
              variant="h6"
              sx={{
                color: "text.primary",
                fontWeight: 500,
                fontSize: "1rem",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              sx={{
                color: "text.primary",
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              {value}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                bgcolor: `${color}15`,
                p: 1,
                borderRadius: 2,
              }}
            >
              <CircularProgress
                variant="determinate"
                value={percentage}
                size={32}
                sx={{
                  color: color,
                  "& .MuiCircularProgress-circle": {
                    strokeLinecap: "round",
                  },
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: color,
                  ml: 1,
                  fontWeight: "medium",
                  fontSize: "0.875rem",
                }}
              >
                {percentage}%
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

// Chart Card Component with enhanced styling
const ChartCard = ({ title, children, action }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        borderRadius: 3,
        background: "white",
        border: "1px solid",
        borderColor: "grey.200",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card
        sx={{
          height: "100%",
          background: "transparent",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "text.primary",
                fontSize: "1.1rem",
              }}
            >
              {title}
            </Typography>
            {action}
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {children}
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

// Progress List Component with enhanced styling
const ProgressList = ({ title, items }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        borderRadius: 3,
        p: 3,
        background: "white",
        border: "1px solid",
        borderColor: "grey.200",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: "1.1rem",
            color: "text.primary",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "grey.50",
            px: 2,
            py: 0.5,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              fontWeight: 500,
            }}
          >
            Month ▼
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
        {items.map((item, index) => (
          <Box key={index}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1.5 }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor: item.color,
                    mr: 1.5,
                  }}
                />
                {item.label}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {item.value?.toLocaleString()}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 6,
                bgcolor: "grey.100",
                borderRadius: 3,
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: `${item.percentage}%`,
                  height: "100%",
                  bgcolor: item.color,
                  borderRadius: 3,
                  transition: "width 0.5s ease-in-out",
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default function HomePage() {
  const theme = useTheme();
  const [stats, setStats] = useState(null);
  const [charts, setCharts] = useState(null);
  const [networkStats, setNetworkStats] = useState(null);
  const [brands, setBrands] = useState([]);
  const [meterTypes, setMeterTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [meterStatus, setMeterStatus] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching data...");

        // Fetch each endpoint separately to better handle errors
        let statsData = null;
        let chartsData = null;
        let networkData = null;
        let brandsData = null;
        let meterTypesData = null;
        let meterStatusData = null;

        try {
          const statsResponse = await fetchWithTimeout(
            "/api/customers/summary/stats"
          );
          if (statsResponse.ok) {
            statsData = await statsResponse.json();
            console.log("Stats data loaded successfully");
          }
        } catch (error) {
          console.error("Stats data fetch error:", error);
        }

        try {
          const chartsResponse = await fetchWithTimeout(
            `/api/customers/all/charts`
          );
          if (chartsResponse.ok) {
            chartsData = await chartsResponse.json();
            console.log("Charts data loaded successfully");
          }
        } catch (error) {
          console.error("Charts data fetch error:", error);
        }

        try {
          const networkResponse = await fetchWithTimeout("/api/network/stats");
          if (networkResponse.ok) {
            networkData = await networkResponse.json();
            console.log("Network data loaded successfully");
          }
        } catch (error) {
          console.error("Network data fetch error:", error);
        }

        try {
          const brandsResponse = await fetchWithTimeout(
            "/api/network/mastermeters/brands"
          );
          if (brandsResponse.ok) {
            brandsData = await brandsResponse.json();
            console.log("Brands data loaded successfully");
          }
        } catch (error) {
          console.error("Brands data fetch error:", error);
        }

        try {
          const meterTypesResponse = await fetchWithTimeout(
            "/api/customermeters/types"
          );
          if (meterTypesResponse.ok) {
            meterTypesData = await meterTypesResponse.json();
            console.log("Meter types data loaded successfully");
          }
        } catch (error) {
          console.error("Meter types fetch error:", error);
        }

        try {
          const meterStatusResponse = await fetchWithTimeout(
            `/api/customermeters/status?year=${selectedYear}`
          );
          if (meterStatusResponse.ok) {
            meterStatusData = await meterStatusResponse.json();
            console.log("Meter status data loaded successfully");
          }
        } catch (error) {
          console.error("Meter status fetch error:", error);
        }

        // Update state with available data
        if (statsData) setStats(statsData);
        if (chartsData) setCharts(chartsData);
        if (networkData) setNetworkStats(networkData);
        if (brandsData) setBrands(brandsData || []);
        if (meterTypesData) setMeterTypes(meterTypesData || []);
        if (meterStatusData) setMeterStatus(meterStatusData || []);

        setLoading(false);
      } catch (err) {
        console.error("Fetch error details:", {
          message: err.message,
          stack: err.stack,
        });
        setError(err.message || "Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 4,
        marginTop: 2,
        backgroundColor: "#F8FAFC", // Light gray background
        minHeight: "100vh",
      }}
    >
      {/* Summary Cards */}
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Total Customers"
            value={stats?.totalCustomers || 0}
            percentage={75}
            color={theme.palette.primary.main}
            icon={PeopleAltOutlined}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="O&M Incidents"
            value={stats?.totalIncidents || 0}
            percentage={65}
            color={theme.palette.warning.main}
            icon={ReportProblemOutlined}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="Sewered vs Unsewered"
            value={`${stats?.seweredStats?.sewered || 0} / ${
              stats?.seweredStats?.unsewered || 0
            }`}
            percentage={(
              ((stats?.seweredStats?.sewered || 0) /
                (stats?.seweredStats?.total || 1)) *
              100
            ).toFixed(1)}
            color={theme.palette.success.main}
            icon={WaterDrop}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SummaryCard
            title="NRW Ratio"
            value={`${stats?.nrwRatio || 0}%`}
            percentage={100 - (stats?.nrwRatio || 0)}
            color={theme.palette.error.main}
            icon={WaterDamage}
          />
        </Grid>
      </Grid>

      {/* Add spacing */}
      <Box mb={2} />

      {/* Main Content Section */}
      <Grid container spacing={2} sx={{ height: "calc(100vh - 200px)" }}>
        {/* Left Column - Charts */}
        <Grid item xs={12} md={8}>
          <Grid
            container
            spacing={2}
            sx={{
              height: "100%",
              "& > .MuiGrid-item": {
                height: "calc(33.33% - 16px)",
              },
            }}
          >
            {/* Chart 1 - Master Meters by Brand */}
            <Grid item xs={12}>
              <ChartCard title="Master Meters by Brand">
                <Box sx={{ width: "100%", height: "100%" }}>
                  <BarChart
                    height={200}
                    series={[
                      {
                        data: brands.map((item) => item.value),
                        label: "Number of Meters",
                        color: theme.palette.secondary.main,
                        barWidth: 20,
                        gradient: {
                          from: theme.palette.secondary.light,
                          to: theme.palette.secondary.dark,
                        },
                      },
                    ]}
                    xAxis={[
                      {
                        data: brands.map((item) => item.name),
                        scaleType: "band",
                        tickLabelStyle: {
                          fontSize: 10,
                          angle: 0,
                          textAnchor: "middle",
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        label: "Number of Meters",
                        scaleType: "linear",
                        min: 0,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                  />
                </Box>
              </ChartCard>
            </Grid>

            {/* Chart 2 - Meter Types */}
            <Grid item xs={12}>
              <ChartCard title="Meter Types">
                <Box sx={{ width: "100%", height: "100%" }}>
                  <LineChart
                    height={200}
                    series={[
                      {
                        data: meterTypes.map((item) => item.value),
                        label: "Number of Meters",
                        color: theme.palette.primary.main,
                        showMark: true,
                        curve: "monotoneX", // Smooth the line
                        lineWidth: 2,
                        markStyle: {
                          fill: theme.palette.primary.light,
                          stroke: theme.palette.primary.dark,
                          strokeWidth: 2,
                        },
                      },
                    ]}
                    xAxis={[
                      {
                        data: meterTypes.map((item) => item.name),
                        scaleType: "point",
                        tickLabelStyle: {
                          fontSize: 10,
                          angle: 0,
                          textAnchor: "middle",
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        label: "Number of Meters",
                        scaleType: "linear",
                        min: 0,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                  />
                </Box>
              </ChartCard>
            </Grid>

            {/* Chart 3 - Customer Meters by Status */}
            <Grid item xs={12}>
              <ChartCard
                title="Customer Meters by Status"
                action={
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={selectedYear}
                      label="Year"
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      {[...Array(5)].map((_, i) => {
                        const year = new Date().getFullYear() - i;
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                }
              >
                <Box sx={{ width: "100%", height: "100%" }}>
                  <BarChart
                    height={200}
                    series={[
                      {
                        data: meterStatus.map((item) => item.Dilapidated),
                        label: "Dilapidated",
                        color: theme.palette.grey[500],
                        stack: "total",
                        barWidth: 15,
                      },
                      {
                        data: meterStatus.map((item) => item.Abandoned),
                        label: "Abandoned",
                        color: theme.palette.error.main,
                        stack: "total",
                        barWidth: 15,
                      },
                      {
                        data: meterStatus.map((item) => item.Dormant),
                        label: "Dormant",
                        color: theme.palette.warning.main,
                        stack: "total",
                        barWidth: 15,
                      },
                      {
                        data: meterStatus.map((item) => item.Active),
                        label: "Active",
                        color: theme.palette.success.main,
                        stack: "total",
                        barWidth: 15,
                      },
                    ]}
                    xAxis={[
                      {
                        data: meterStatus.map((item) => item.month),
                        scaleType: "band",
                        tickLabelStyle: {
                          fontSize: 10,
                          angle: 0,
                          textAnchor: "middle",
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        label: "Number of Meters",
                        scaleType: "linear",
                        min: 0,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "bottom", horizontal: "middle" },
                        padding: 0,
                      },
                    }}
                  />
                </Box>
              </ChartCard>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Column - Progress Lists */}
        <Grid item xs={12} md={4}>
          <Grid
            container
            spacing={2}
            sx={{
              height: "100%",
              "& > .MuiGrid-item": {
                height: "calc(50% - 8px)",
              },
            }}
          >
            {/* Water Network Stats */}
            <Grid item xs={12}>
              <ProgressList
                title="Water Network"
                items={[
                  {
                    label: "Customer Meters",
                    percentage: Math.min(
                      100,
                      ((networkStats?.customerMeters || 0) / 10000) * 100
                    ),
                    value: networkStats?.customerMeters || 0,
                    color: theme.palette.primary.main,
                  },
                  {
                    label: "Master Meters",
                    percentage: Math.min(
                      100,
                      ((networkStats?.masterMeters || 0) / 100) * 100
                    ),
                    value: networkStats?.masterMeters || 0,
                    color: theme.palette.warning.main,
                  },
                  {
                    label: "Production Meters",
                    percentage: Math.min(
                      100,
                      ((networkStats?.productionMeters || 0) / 50) * 100
                    ),
                    value: networkStats?.productionMeters || 0,
                    color: theme.palette.success.main,
                  },
                  {
                    label: "Bulk Meters",
                    percentage: Math.min(
                      100,
                      ((networkStats?.bulkMeters || 0) / 50) * 100
                    ),
                    value: networkStats?.bulkMeters || 0,
                    color: theme.palette.error.main,
                  },
                  {
                    label: "Water Pipes",
                    percentage: Math.min(
                      100,
                      ((networkStats?.waterPipes || 0) / 1000) * 100
                    ),
                    value: networkStats?.waterPipes || 0,
                    color: theme.palette.info.main,
                  },
                  {
                    label: "Tanks",
                    percentage: Math.min(
                      100,
                      ((networkStats?.tanks || 0) / 20) * 100
                    ),
                    value: networkStats?.tanks || 0,
                    color: theme.palette.secondary.main,
                  },
                ]}
              />
            </Grid>

            {/* Progress List 2 */}
            <Grid item xs={12}>
              <ProgressList
                title="Sewer Network"
                items={[
                  {
                    label: "Manholes",
                    percentage: Math.min(
                      100,
                      ((networkStats?.manholes || 0) / 1000) * 100
                    ),
                    value: networkStats?.manholes || 0,
                    color: theme.palette.primary.main,
                  },
                  {
                    label: "Connection Chambers",
                    percentage: Math.min(
                      100,
                      ((networkStats?.connectionChambers || 0) / 500) * 100
                    ),
                    value: networkStats?.connectionChambers || 0,
                    color: theme.palette.warning.main,
                  },
                  {
                    label: "Customer Chambers",
                    percentage: Math.min(
                      100,
                      ((networkStats?.customerChambers || 0) / 500) * 100
                    ),
                    value: networkStats?.customerChambers || 0,
                    color: theme.palette.success.main,
                  },
                  {
                    label: "Sewer Lines",
                    percentage: Math.min(
                      100,
                      ((networkStats?.sewerLines || 0) / 500) * 100
                    ),
                    value: networkStats?.sewerLines || 0,
                    color: theme.palette.info.main,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
